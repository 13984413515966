const { isEmpty } = require("lodash");
const { showBrowserNotification } = require("./ui_interactions");
const { parseChatMessage } = require("./chat_features");
const {
    insereOportunidadeKanban,
    enviaOportunidadeParaOReact,
} = require("./ajax_requests");

export function conexaoWebSocketElocaHub(context) {
    const box = $(".js-eloca-hub-bell", context);
    const rotaOportunidades = $(box).data("rota-oportunidades");
    const rotaOportunidadeShow = $(box).data("rota-oportunidade-show");

    const userPipelines = JSON.parse(window.userPipelines);

    try {
        const socket = new WebSocket(window.elocaHubWebSocketUrl);

        socket.onmessage = (event) => {
            const dados = JSON.parse(event.data || "{}");
            const body = dados.body;
            const oportunidadeId = body.oportunidade_id;
            const configElocaHubId = body.configElocaHubId;
            const oportunidadeNome = body.oportunidade_nome;

            let pipelineId = parseInt(body.pipeline_id);
            if (isNaN(pipelineId)) {
                pipelineId = 0;
            }

            $(".js-sem-notificacao", box).remove();
            if (
                dados.action == "novaOportunidade" &&
                userPipelines.includes(pipelineId)
            ) {
                const descricao =
                    "Nova oportunidade criada via " + body.channel;

                $(".js-area-notificacoes", box).prepend(`
                        <a class="dropdown-item" href="${rotaOportunidades}">
                            <div class="d-flex align-items-center">
                                <div class="mr-3">
                                    <div class="icon-circle bg-success p-2">
                                        <i class="fab fa-whatsapp text-white"></i>
                                    </div>
                                </div>
                                <span>${descricao}</span>
                            </div>
                        </a>
                    `);
                showBrowserNotification(
                    "Nova oportunidade",
                    descricao,
                    rotaOportunidades,
                    body.icon
                );

                const kanbanPipeline = parseInt($("#kanban").data("pipeline"));

                // Coloca a oportunidade na barra lateral de oportunidade
                if ($("#minhas-oportunidades").length) {
                    insereOportunidadeKanban(
                        oportunidadeId,
                        "minhas-oportunidades"
                    );
                }

                if ($("#kanban").length && kanbanPipeline == pipelineId) {
                    insereOportunidadeKanban(oportunidadeId, "kanban");
                }

                if ($("#react-app").length) {
                    enviaOportunidadeParaOReact(oportunidadeId);
                }
            } else if (dados.action == "novaMensagem") {
                if (
                    $(
                        `.js-container-chat#oportunidade-${oportunidadeId}-${configElocaHubId}`,
                        context
                    ).length
                ) {
                    let direcao = "in";
                    if (body.mensagem && body.mensagem.direcao) {
                        direcao = body.mensagem.direcao;
                    }
                    const contextChat = $(
                        `.js-container-chat#oportunidade-${oportunidadeId}-${configElocaHubId}`
                    ).closest(".oportunidade-chat");
                    $(".js-aguardando-resposta", contextChat).remove();
                    parseChatMessage(
                        contextChat,
                        body,
                        body.protocoloId,
                        direcao
                    );
                } else {
                    const rota = rotaOportunidadeShow.replace(
                        "xxx",
                        oportunidadeId
                    );

                    var hasNotificacaoWebSocket = document
                        .querySelector(".js-area-notificacoes")
                        .contains(
                            document.querySelector(
                                ".noti-mensagem" + oportunidadeId
                            )
                        );
                    if (!hasNotificacaoWebSocket) {
                        $(".js-area-notificacoes", box).prepend(`
                            <a class="dropdown-item noti-mensagem ${oportunidadeId}" href="${rota}">
                                <div class="d-flex align-items-center">
                                    <div class="mr-3">
                                        <div class="icon-circle bg-primary p-2">
                                            <i class="fas fa-comment text-white"></i>
                                        </div>
                                    </div>
                                    <span>Nova(s) mensagem(gens) no Chat (Oportunidade ${oportunidadeId} - ${oportunidadeNome})</span>
                                </div>
                            </a>`);
                    }

                    let mensagem = "Mensaagem recebida";
                    if (
                        body.mensagem.mensagem &&
                        body.mensagem.mensagem.trim() !== ""
                    ) {
                        mensagem = body.mensagem.mensagem;
                    } else if (
                        body.mensagem &&
                        body.mensagem.fileUrl &&
                        body.mensagem.fileUrl.trim() !== ""
                    ) {
                        mensagem = "Arquivo recebido";
                    }

                    showBrowserNotification(
                        body.cliente_nome + " via " + body.channel,
                        mensagem,
                        rota,
                        body.icon
                    );

                    if (
                        $("#kanban").length > 0 ||
                        $("#minhas-oportunidades").length > 0
                    ) {
                        novaMensagemUpdate(oportunidadeId);
                    }
                    if ($("#react-app").length) {
                        novaMensagemUpdateReact(oportunidadeId);
                    }
                }
            } else if (dados.action == "oportunidadeTransferida") {
                const rota = rotaOportunidadeShow.replace(
                    "xxx",
                    oportunidadeId
                );
                $(".js-area-notificacoes", box).prepend(`
                            <a class="dropdown-item" href="${rota}">
                                <div class="d-flex align-items-center">
                                    <div class="mr-3">
                                        <div class="icon-circle bg-primary p-2">
                                            <i class="fas fa-comment text-white"></i>
                                        </div>
                                    </div>
                                    <span>Uma oportunidade foi transferida para você</span>
                                </div>
                            </a>
                        `);
                showBrowserNotification(
                    "Nova mensagem",
                    "Uma oportunidade foi transferida para você",
                    rota
                );
            } else if (dados.action == "novaNotificacao") {
                if (body.tipo == "reacao") {
                    if ($(`.${body.gupshup_message_id}`, context).length) {
                        if (body.reacao) {
                            $(`.${body.gupshup_message_id}`, context).addClass(
                                "com-reacao"
                            );
                            $(
                                `.${body.gupshup_message_id} .js-mensagem-reacao`,
                                context
                            ).removeClass("d-none");
                            $(
                                `.${body.gupshup_message_id} .js-mensagem-reacao .js-reacao-cliente`,
                                context
                            ).html(body.reacao);
                        } else {
                            $(
                                `.${body.gupshup_message_id}`,
                                context
                            ).removeClass("com-reacao");
                            $(
                                `.${body.gupshup_message_id} .js-mensagem-reacao`,
                                context
                            ).addClass("d-none");
                            $(
                                `.${body.gupshup_message_id} .js-mensagem-reacao .js-reacao-cliente`,
                                context
                            ).html("");
                        }
                    } else if ($(`.${body.gupshup_ref}`, context).length) {
                        if (body.reacao) {
                            $(`.${body.gupshup_ref}`, context).addClass(
                                "com-reacao"
                            );
                            $(
                                `.${body.gupshup_ref} .js-mensagem-reacao`,
                                context
                            ).removeClass("d-none");
                            $(
                                `.${body.gupshup_ref} .js-mensagem-reacao .js-reacao-cliente`,
                                context
                            ).html(body.reacao);
                        } else {
                            $(`.${body.gupshup_ref}`, context).removeClass(
                                "com-reacao"
                            );
                            $(
                                `.${body.gupshup_ref} .js-mensagem-reacao`,
                                context
                            ).addClass("d-none");
                            $(
                                `.${body.gupshup_ref} .js-mensagem-reacao .js-reacao-cliente`,
                                context
                            ).html("");
                        }
                    }
                } else if (body.tipo == "check") {
                    if (
                        $(
                            `.js-container-chat#oportunidade-${oportunidadeId}-${configElocaHubId}`,
                            context
                        ).length
                    ) {
                        if (body.message_status > 0) {
                            $(
                                `.${body.gupshup_message_id} .js-status-message`
                            ).addClass("d-none");
                            let statusText = "";
                            switch (body.message_status) {
                                case 1:
                                    statusText = "enviada";
                                    break;
                                case 2:
                                    statusText = "entregue";
                                    break;
                                case 3:
                                    statusText = "lida";
                                    break;
                                default:
                                    statusText = "";
                            }
                            if (isEmpty(statusText) == false) {
                                $(
                                    `.${body.gupshup_message_id}-${statusText}`
                                ).removeClass("d-none");
                            }
                        }
                    }
                } else {
                    const titulo = body.tituloNotificacao;
                    const descricao = body.descricaoNotificacao;
                    const rota = rotaOportunidadeShow.replace(
                        "xxx",
                        oportunidadeId
                    );
                    $(".js-area-notificacoes", box).prepend(`
                            <a class="dropdown-item" href="${rota}">
                                <div class="d-flex align-items-center">
                                    <div class="mr-3">
                                        <div class="icon-circle bg-success p-2">
                                            <i class="fas fa-bell fa-fw"></i>
                                        </div>
                                    </div>
                                    <span>${descricao}</span>
                                </div>
                            </a>
                        `);
                    showBrowserNotification(titulo, descricao, rota);
                }
            } else if (dados.action == "qrcode") {
                //qrcode
            } else if (dados.action == "retornarQrcode") {
                //retornarQrcode
            }

            const notificacoes = $(".js-area-notificacoes a", box).length;
            let restoNoti = 0;
            if ($("#resto-noti-js").length) {
                restoNoti = parseInt($("#resto-noti-js").text());
            }
            if (notificacoes > 0) {
                $(".js-badge-alert", box)
                    .removeClass("d-none")
                    .html(notificacoes + restoNoti);
            }
        };
    } catch (error) {
        if (error instanceof DOMException) {
            console.error("Ocorreu uma exceção DOMException:", error.message);
        } else {
            console.error("Ocorreu uma exceção:", error);
        }
    }
}

export function novaMensagemUpdate(oportunidadeId) {
    "";

    let query = `.js-op-${oportunidadeId}`;

    let topoLista = document.querySelector(query).closest(".ui-state-default");

    $(topoLista).parent().prepend($(query));

    if ($(`${query}`).find(".mensagem-nao-lida").text().length) {
        let span = $(query).find(".mensagem-nao-lida");
        let valorAtual = parseInt(span.text());
        let novoValor = valorAtual + 1;
        span.text(novoValor);
    } else {
        let data = new Date().toLocaleString("pt-BR", {
            timeZone: window.fusoHorario,
        });
        let horaAtual = data.split(" ")[1].slice(0, -3);

        $(`${query} .avisos`).html(
            `<span class="mensagem-nao-lida" title="Mensagens não lidas">1</span>`
        );
        $(`${query} .dt`).html(`<span class="small">${horaAtual}</span>`);
    }
}

export function novaMensagemUpdateReact(oportunidadeId) {
    const iframe = document.getElementById("react-app").contentWindow;
    const message = {
        novaMensagem: oportunidadeId,
    };

    iframe.postMessage(message, "*");
}
