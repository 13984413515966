const {
    limpaMoeda,
    isEmpty
} = require("./default_parts/functions/miscellaneous");

Eloca.behaviors.oportunidadesChat = function (context) {
    $(".js-cancelar-produto", context).on("click", function () {
        const contextChat = $(this).closest(".oportunidade-chat");
        $(".js-id-produto", contextChat).val("");
    });

    function atualizarTotalTabelaCCLChat(sidebar, principal) {
        const tabelaPrincipal = $("#tabela_produtos");
        const table = $(sidebar).find(".js-tabela-produtos-chat");
        let total = 0;
        let valorProtecao = 0;
        let totalDesconto = 0;
        $(table)
            .find(".subtotalSpanProd")
            .each(function () {
                let val = $(this).data("id");
                val = limpaMoeda(val);
                total += val;
                const protecao = $(this).data("percentual-protecao") ?? 0;
                const desconto = $(this).data("desconto-valor") ?? 0;
                valorProtecao += (parseFloat(protecao) / 100) * val;
                totalDesconto += parseFloat(desconto);
            });

        if (
            $(sidebar).find("input[name='protecao']").length > 0 &&
            !$(sidebar).find("input[name='protecao']").prop("checked")
        ) {
            valorProtecao = 0;
        }

        if (principal) {
            $(tabelaPrincipal)
                .find("#produtoOportunidadeTotal")
                .html(
                    total.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })
                );
        }

        totalDesconto = !isNaN(totalDesconto) ? totalDesconto : 0;
        total += valorProtecao;
        let valorProtecaoProtecaoBRL = valorProtecao.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });

        $(table).find(".js-valor-protecao-ccl").html(valorProtecaoProtecaoBRL);
        $(table)
            .find(".js-valor-protecao-ccl")
            .attr("data-valor", valorProtecao);

        let seletorValorEntrega = $(sidebar).find(
            "input[name='valor_deslocamento_entrega']"
        );
        let seletorValorRetirada = $(sidebar).find(
            "input[name='valor_deslocamento_retirada']"
        );

        if (
            $(sidebar).find("input[name='entrega']").length > 0 &&
            $(sidebar).find("input[name='entrega']").prop("checked")
        ) {
            $(table).find(".js-tr-deslocamento-entrega").show();
            total +=
                seletorValorEntrega.length > 0
                    ? limpaMoeda(seletorValorEntrega.val())
                    : 0;
        } else {
            $(table).find(".js-tr-deslocamento-entrega").hide();
            $(table).find("input[name='valor_deslocamento_entrega']").val("0");
        }

        total +=
            seletorValorRetirada.length > 0
                ? limpaMoeda(seletorValorRetirada.val())
                : 0;
        $(table)
            .find("#produtoOportunidadeTotal")
            .html(
                total.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })
            );
        if (principal) {
            $(tabelaPrincipal)
                .find(".js-total-produtos-oportunidade-ccl")
                .html(
                    total.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })
                );
        }
    }

    $(".js-tabela-produto-chat", context).on(
        "change",
        ".js-dados-oportunidade-chat",
        function () {
            const tabela = $(this).closest(".js-tabela-produto-chat");
            const tabelaPrincipal = $("#tabela_produtos_principal");
            const trId = "#" + $(this).closest("tr").attr("id");
            let oportunidadeId = $("input[name='oportunidade_id']").val();

            let observacao = $(tabela)
                .find("textarea[name='observacao']")
                .val();
            $(tabelaPrincipal)
                .find("textarea[name='observacao']")
                .val(observacao);
            let valorDeslocamentoEntrega = $(tabela)
                .find("input[name='valor_deslocamento_entrega']")
                .val();
            valorDeslocamentoEntrega = limpaMoeda(valorDeslocamentoEntrega);
            valorDeslocamentoEntrega = parseFloat(valorDeslocamentoEntrega);
            $(tabela)
                .find("input[name='valor_deslocamento_entrega']")
                .val(
                    valorDeslocamentoEntrega.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                    })
                );
            $(tabelaPrincipal)
                .find("input[name='valor_deslocamento_entrega']")
                .val(
                    valorDeslocamentoEntrega.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                    })
                );

            let valorDeslocamentoRetirada = $(tabela)
                .find("input[name='valor_deslocamento_retirada']")
                .val();
            valorDeslocamentoRetirada = limpaMoeda(valorDeslocamentoRetirada);
            valorDeslocamentoRetirada = parseFloat(valorDeslocamentoRetirada);
            $(tabela)
                .find("input[name='valor_deslocamento_retirada']")
                .val(
                    valorDeslocamentoRetirada.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                    })
                );
            $(tabelaPrincipal)
                .find("input[name='valor_deslocamento_retirada']")
                .val(
                    valorDeslocamentoRetirada.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                    })
                );

            valorDeslocamentoRetirada = limpaMoeda(valorDeslocamentoRetirada);

            atualizarDadosOportunidade(
                oportunidadeId,
                observacao,
                valorDeslocamentoEntrega,
                valorDeslocamentoRetirada,
                trId
            );
        }
    );

    function atualizarDadosOportunidade(
        oportunidadeId,
        observacao,
        valorDeslocamentoEntrega,
        valorDeslocamentoRetirada,
        idAtaulizacao
    ) {
        $.ajax({
            url: siteUrl(`/painel/oportunidades/dados`),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                oportunidade_id: oportunidadeId,
                observacao: observacao,
                valor_deslocamento_entrega: valorDeslocamentoEntrega,
                valor_deslocamento_retirada: valorDeslocamentoRetirada,
            },
            success: function (res) {
                const result = JSON.parse(res);
                if (result.status == "erro") {
                    showToastDanger($("body"), result.mensagem);
                }
                if (result.status == "sucesso") {
                    autalizarTotalTabela("tabela_produtos_principal");
                    autalizarTotalTabela(idAtaulizacao);
                    showToastSuccess(context, result.mensagem);
                }
            },
            error: function (err) {
                showToastDanger(
                    context,
                    "Ocorreu um erro ao tentar incluir os produtos"
                );
            },
        });
    }
    function atualizarDadosOportunidadeChat(sidebar) {
        const tabelaPrincipal = $("#tabela_produtos_principal");
        let oportunidadeId = $("input[name='oportunidade_id']").val();
        let protecao =
            $(sidebar).find("input[name='protecao']").length > 0
                ? $(sidebar).find("input[name='protecao']").prop("checked")
                : null;
        let entrega =
            $(sidebar).find("input[name='entrega']").length > 0
                ? $(sidebar).find("input[name='entrega']").prop("checked")
                : null;
        let dataEntrega = $("input[name='data_entrega']").val();
        let observacao = $(sidebar).find("textarea[name='observacao']").val();
        let clienteEnderecoId = $(sidebar)
            .find("select[name='cliente_endereco_id']")
            .val();

        let valorDeslocamentoEntrega = $(sidebar)
            .find("input[name='valor_deslocamento_entrega']")
            .val();
        valorDeslocamentoEntrega = limpaMoeda(valorDeslocamentoEntrega);
        valorDeslocamentoEntrega = parseFloat(valorDeslocamentoEntrega);
        $(sidebar)
            .find("input[name='valor_deslocamento_entrega']")
            .val(
                valorDeslocamentoEntrega.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                })
            );
        $(tabelaPrincipal)
            .find("input[name='valor_deslocamento_entrega']")
            .val(
                valorDeslocamentoEntrega.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                })
            );

        let valorDeslocamentoRetirada = $(sidebar)
            .find("input[name='valor_deslocamento_retirada']")
            .val();
        valorDeslocamentoRetirada = limpaMoeda(valorDeslocamentoRetirada);
        valorDeslocamentoRetirada = parseFloat(valorDeslocamentoRetirada);
        $(sidebar)
            .find("input[name='valor_deslocamento_retirada']")
            .val(
                valorDeslocamentoRetirada.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                })
            );
        $(tabelaPrincipal)
            .find("input[name='valor_deslocamento_retirada']")
            .val(
                valorDeslocamentoRetirada.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                })
            );

        if (!isEmpty(dataEntrega)) {
            dataEntrega = dataEntrega.split("/");
            dataEntrega = dataEntrega.reverse().join("-");
        }

        if (!entrega) {
            valorDeslocamentoEntrega = 0;
        }

        $.ajax({
            url: siteUrl(`/painel/oportunidades/ccl/dados`),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                oportunidade_id: oportunidadeId,
                protecao: protecao,
                entrega: entrega,
                data_entrega: dataEntrega,
                valor_deslocamento_entrega: valorDeslocamentoEntrega,
                valor_deslocamento_retirada: valorDeslocamentoRetirada,
                observacao: observacao,
                cliente_endereco_id: clienteEnderecoId,
            },
            success: function (res) {
                if (res.status == "erro") {
                    showToastDanger($("body"), res.mensagem);
                }
                atualizarTotalTabelaCCLChat(sidebar, true);
            },

            error: function (err) {
                console.log("err", err);
            },
        });
    }

    $(".js-tabela-produto-chat", context).on(
        "change",
        ".js-dados-integracao-ccl-oportunidade-chat",
        function () {
            const sidebar = $(this).closest(".sidebar-chat");
            atualizarDadosOportunidadeChat(sidebar);
        }
    );

    $(".js-dados-integracao-ccl-oportunidade-chat", context).on(
        "change",
        function () {
            const sidebar = $(this).closest(".sidebar-chat");
            atualizarDadosOportunidadeChat(sidebar);
        }
    );

    $(".js-autocomplete-produto-oportunidades-chat", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete?crm=true"),
                minLength: 2,
                select: function (event, ui) {
                    const modal = $(this)
                        .closest(".sidebar-chat")
                        .find("#addProdutosModal");
                    $(modal).modal();
                    $(modal)
                        .find("#addProdutosModalLabel", context)
                        .html("Adicionar produto");
                    $(modal).find("#produto_oportunidade_id", context).val("");
                    $(modal)
                        .find("#produto_id", context)
                        .val(ui.item.id ?? " - ");
                    $(modal)
                        .find("#sku-chat", context)
                        .html(ui.item.sku ?? " - ");
                    $(modal)
                        .find("#produto-nome", context)
                        .html(ui.item.nome ?? " - ");
                    let preco = 0;
                    if (ui.item.faixas_precos.length > 0) {
                        if (
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        if (
                            !$(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        preco = ui.item.faixas_precos[0].preco;
                        $(modal)
                            .find("#faixa-chat", context)
                            .find("option")
                            .remove()
                            .end();
                        $.each(ui.item.faixas_precos, function (i, item) {
                            $(modal)
                                .find("#faixa-chat", context)
                                .append(
                                    $("<option>", {
                                        value: item.id,
                                        text: `${
                                            item.descricao
                                        } - R$ ${item.preco.toLocaleString(
                                            "pt-br",
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}`,
                                    }).attr("data-id", item.preco)
                                );
                        });
                    } else {
                        if (
                            !$(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        if (
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        preco = ui.item.preco;
                        $("#bloco-faixa-chat", context).attr(
                            "data-preco",
                            preco
                        );
                        $(modal)
                            .find("#preco-faixa-chat", context)
                            .val(
                                preco.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })
                            );
                    }
                    $(modal)
                        .find("#bloco-faixa-chat", context)
                        .attr("data-preco", preco);
                    $(modal)
                        .find("#preco-chat", context)
                        .val(
                            preco.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                            })
                        );
                    $(modal)
                        .find("#subtotal-chat", context)
                        .attr("data-id", preco);
                    $(modal)
                        .find("#subtotal-chat", context)
                        .html(
                            preco.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })
                        );
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            const url = siteUrl(`/painel/pedidos/produtosEdit/add/${item.id}`);
            let sku = "";
            if (item.sku && item.sku != null) {
                sku = ` - SKU: ${item.sku}`;
            }

            return $("<li class='lista-produto'>")
                .append(`#${item.id} - ${item.nome}${sku}`)
                .appendTo(ul);
        };
    });

    $(".js-atualiza-faixa-chat", context).on("change", function () {
        context = $(this).closest("form");
        const value = $(this).val();
        const option = $(this).find(`option`).filter(`[value=${value}]`);
        const preco = $(option).data("id");
        $("#bloco-faixa-chat", context).attr("data-preco", preco);
        $("#preco-chat", context).val(
            preco.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
            })
        );
        calcularPrecosChat();
    });

    $(".js-input-produto-chat").on("input", function () {
        context = $(this).closest("form");
        calcularPrecosChat();
    });

    $(".js-input-produto-chat").on("change", function () {
        context = $(this).closest("form");
        let preco = $("#preco-chat", context).val();
        preco = limpaMoeda(preco);
        $("#preco-chat", context).val(
            preco.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
            })
        );
    });

    function calcularPrecosChat() {
        const precoTabela = $("#bloco-faixa-chat", context).data("preco");
        let preco = $("#preco-chat", context).val();
        preco = limpaMoeda(preco);
        const quantidade = $("#qdt-chat").val();
        let desconto = precoTabela - preco;
        desconto = (desconto * 100) / precoTabela;
        desconto = desconto < 0 ? 0 : desconto;
        $("#desconto-chat", context).html(
            `${desconto.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
            })}%`
        );
        $("#subtotal-chat", context).html(
            (preco * quantidade).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            })
        );
    }

    $(".js-cancelar-modal-produto-chat", context).on("click", function () {
        fecharModalChat($(this).closest("form"));
    });

    function fecharModalChat(context) {
        $("#produto_oportunidade_id", context).html("");
        $("#produto_id", context).html("");
        $("#sku-chat", context).html(" - ");
        $("#produto-nome", context).html(" - ");
        $("#qdt-chat", context).val(1);
        $("#preco-chat", context).val("0,00");
        $("#desconto-chat", context).html("0,00");
        $("#subtotal-chat", context).html("R$ 0,00");
        if (!$("#bloco-faixa-chat", context).hasClass("d-none")) {
            $("#bloco-faixa-chat", context).addClass("d-none");
        }
        if (!$("#bloco-preco-faixa-chat", context).hasClass("d-none")) {
            $("#bloco-preco-faixa-chat", context).addClass("d-none");
        }
    }

    $(".js-sidebar-chat-toggle", context).on("click", function () {
        const target = "#" + $(this).data("target");
        const targetTabela = "#" + $(this).data("load");
        const id = $(this).data("id");
        if ($(target).hasClass("d-none")) {
            carregarTebelaChat(targetTabela, id);
        }
        $(target).toggleClass("d-none");
        $(this).find("svg").toggleClass("d-none");
    });

    $(".js-tabela-produto-chat", context).on(
        "click",
        ".js-delete-item-ajax",
        function () {
            const modal = $(this).closest(".modal");
            const rota = $(modal).find('input[name="rota"]', context).val();
            const oportunidadeId = $(modal)
                .find('input[name="oportunidade"]')
                .val();
            const prudutoTr =
                "#tr-item-" + $(modal).find('input[name="op_id"]').val();
            const targetTabela =
                "#" +
                $(modal)
                    .closest(".sidebar-chat")
                    .find(".js-tabela-produto-chat")
                    .attr("id");
            $(modal).modal();
            $(".modal-backdrop").remove();

            $.ajax({
                url: rota,
                type: "delete",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                success: function (res) {
                    carregarTebelaChat(targetTabela, oportunidadeId);
                    $(prudutoTr).remove();
                    autalizarTotalTabela("tabela_produtos_principal");
                },
                error: function (err) {
                    showToastDanger(modal);
                },
            });
        }
    );
    function carregarTebelaChat(targetTabela, id) {
        $(targetTabela).html(`
            <div class="mt-5 mb-4 pb-1" style="position: relative;">
                <div class="loading-circle"><div></div><div></div><div></div><div></div></div>
            </div>
        `);
        $.ajax({
            url: siteUrl(`/painel/oportunidade-tabela-produto-chat/${id}`),
            type: "get",
            success: function (res) {
                $(targetTabela).html(res);
            },
        });
    }

    $(".js-autocomplete-produto-oportunidades-ccl-chat", context).each(
        function () {
            const input = this;
            $(input)
                .autocomplete({
                    source: siteUrl(
                        "/painel/produtos/busca/autocomplete?crm=true"
                    ),
                    minLength: 2,
                    select: function (event, ui) {
                        const modal = $(this)
                            .closest(".sidebar-chat")
                            .find("#addProdutosModal");
                        $(modal).modal();

                        $(modal)
                            .find("#addProdutosModalLabel", context)
                            .html("Adicionar produto");

                        $("#overlayer-loading").removeClass("show-overlayer");
                        $(modal)
                            .find("#produto_id", context)
                            .val(ui.item.id ?? " - ");
                        $(modal)
                            .find("#sku-chat", context)
                            .html(ui.item.sku ?? " - ");
                        $(modal)
                            .find("#produto-nome", context)
                            .html(ui.item.nome ?? " - ");
                        let preco = 0;
                        if (ui.item.faixas_precos.length > 0) {
                            if (
                                $(modal)
                                    .find("#bloco-faixa-chat", context)
                                    .hasClass("d-none")
                            ) {
                                $(modal)
                                    .find("#bloco-faixa-chat", context)
                                    .removeClass("d-none");
                            }
                            if (
                                !$(modal)
                                    .find("#bloco-preco-faixa-chat", context)
                                    .hasClass("d-none")
                            ) {
                                $(modal)
                                    .find("#bloco-preco-faixa-chat", context)
                                    .addClass("d-none");
                            }
                            preco = ui.item.faixas_precos[0].preco;
                            $(modal)
                                .find("#faixa-chat", context)
                                .find("option")
                                .remove()
                                .end();
                            $.each(ui.item.faixas_precos, function (i, item) {
                                $(modal)
                                    .find("#faixa-chat", context)
                                    .append(
                                        $("<option>", {
                                            value: item.id,
                                            text: `${
                                                item.descricao
                                            } - R$ ${item.preco.toLocaleString(
                                                "pt-br",
                                                {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }
                                            )}`,
                                        }).attr("data-id", item.preco)
                                    );
                            });
                        } else {
                            if (
                                !$(modal)
                                    .find("#bloco-faixa-chat", context)
                                    .hasClass("d-none")
                            ) {
                                $(modal)
                                    .find("#bloco-faixa-chat", context)
                                    .addClass("d-none");
                            }
                            if (
                                $(modal)
                                    .find("#bloco-preco-faixa-chat", context)
                                    .hasClass("d-none")
                            ) {
                                $(modal)
                                    .find("#bloco-preco-faixa-chat", context)
                                    .removeClass("d-none");
                            }
                            preco = ui.item.preco;
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .attr("data-preco", preco);
                            $(modal)
                                .find("#preco-faixa-chat", context)
                                .val(
                                    preco.toLocaleString("pt-br", {
                                        style: "currency",
                                        currency: "BRL",
                                    })
                                );
                        }
                        $(modal)
                            .find("#bloco-faixa-chat", context)
                            .attr("data-preco", preco);
                        $(modal)
                            .find("#preco-chat", context)
                            .val(
                                preco.toLocaleString("pt-br", {
                                    minimumFractionDigits: 2,
                                })
                            );
                        $(modal)
                            .find("#subtotal-chat", context)
                            .attr("data-id", preco);
                        $(modal)
                            .find("#subtotal-chat", context)
                            .html(
                                preco.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })
                            );
                    },
                })
                .autocomplete("instance")._renderItem = function (ul, item) {
                const url = siteUrl(
                    `/painel/pedidos/produtosEdit/add/${item.id}`
                );
                let sku = "";
                if (item.sku && item.sku != null) {
                    sku = ` - SKU: ${item.sku}`;
                }

                return $("<li class='lista-produto'>")
                    .append(`#${item.id} - ${item.nome}${sku}`)
                    .appendTo(ul);
            };
        }
    );


    window.atualizandoProdutopChat = false;
    $(".js-chat-adcionar-produto-ccl").on("click", function (e) {
        if (window.atualizandoProdutopChat) {
            return false;
        }
        window.atualizandoProdutopChat = true;
        const input = this;
        const form = $(this).closest("form");
        const itemId = $(form).find("#produto_oportunidade_id").val();
        const produtoId = $(form).find("#produto_id").val();
        const oportunidadeId = $(form).find("#oportunidade_id").val();
        const faixaId = $(form).find("#faixa-chat").val();
        const preco = $(form).find("#preco-chat").val();
        const quantidade = $(form).find("#qdt-chat").val();

        if (itemId) {
            atualizarProdutoChatCCL(
                itemId,
                oportunidadeId,
                faixaId,
                preco,
                quantidade,
                input
            );
        } else {
            incluirProdutoChatCCL(
                produtoId,
                oportunidadeId,
                faixaId,
                preco,
                quantidade,
                input
            );
        }
        const modal = $(this).closest("#addProdutosModal");
        $(modal).modal("hide");
    });

    function incluirProdutoChatCCL(
        produtoId,
        oportunidadeId,
        faixaId,
        preco,
        quantidade,
        input
    ) {
        $.ajax({
            url: siteUrl("/painel/oportunidades/ccl/produto"),
            type: "post",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                produto_id: produtoId,
                oportunidade_id: oportunidadeId,
                faixa_id: faixaId,
                preco: preco,
                quantidade: quantidade,
            },

            success: function (res) {
                window.atualizandoProdutopChat = false;
                $("#overlayer-loading").removeClass("show-overlayer");
                context = $(input).closest(".sidebar-chat");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context, "Produto Incluido.");
                    const trChat = res.htmlChat;
                    const tr = res.html;
                    const item_id = res.itemId;
                    $(".js-tabela-produtos-chat tbody", context).append(trChat);
                    Eloca.load($(`#tr-item-chat-${item_id}`));

                    $(".js-tabela-produtos tbody").append(tr);
                    Eloca.load($(`#tr-item-${item_id}`));

                    const sidebar = $(input).closest(".sidebar-chat");
                    atualizarTotalTabelaCCLChat(sidebar, false);

                    if ($(".js-racerregar-modelo").hasClass("d-none")) {
                        $(".js-racerregar-modelo").removeClass("d-none");
                    }
                    fecharModalChat($(this).closest("form"));
                }
            },
            error: function (err) {
                window.atualizandoProdutopChat = false;
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context, err.message);
            },
        });
    }

    function atualizarProdutoChatCCL(
        itemId,
        oportunidadeId,
        faixaId,
        preco,
        quantidade,
        input
    ) {
        $.ajax({
            url: siteUrl("/painel/oportunidades/ccl/produto"),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                item_id: itemId,
                oportunidade_id: oportunidadeId,
                faixa_id: faixaId,
                preco: preco,
                quantidade: quantidade,
            },
            success: function (res) {
                window.atualizandoProdutopChat = false;
                $("#overlayer-loading").removeClass("show-overlayer");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context, res.message);
                    const tr = $(input)
                        .closest(".sidebar-chat")
                        .find(".js-tabela-produtos-chat")
                        .find(`#tr-item-chat-${itemId}`);
                    const trPrincpal = $("#tabela_produtos_principal").find(
                        `#tr-item-${itemId}`
                    );
                    if (quantidade > 0) {
                        const novaTrChat = res.htmlChat;
                        const novaTr = res.html;
                        $(tr, context).replaceWith(novaTrChat);
                        $(trPrincpal).replaceWith(novaTr);
                        Eloca.load($(`#tr-item-chat-${itemId}`));
                        Eloca.load($(`#tr-item-${itemId}`));
                    } else {
                        $(tr).remove();
                        $(trPrincpal).remove();
                    }
                    autalizarTotalTabela(`#tr-item-chat-${itemId}`);
                }
            },
            error: function (err) {
                window.atualizandoProdutopChat = false;
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context, err.message);
            },
        });
    }

    $(".js-tabela-produto-chat", context).on(
        "click",
        ".js-carregar-produto-chat",
        function () {
            const produtoChat = this;
            $("#overlayer-loading").addClass("show-overlayer");
            const proOpId = $(this)
                .closest(".js-tr-produtos")
                .find(".js-item-id")
                .val();
            $.ajax({
                url: siteUrl(`/painel/oportunidade-produto/${proOpId}`),
                type: "GET",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                success: function (res) {
                    $("#overlayer-loading").removeClass("show-overlayer");
                    const modal = $(produtoChat)
                        .closest(".sidebar-chat")
                        .find("#addProdutosModal");

                    $(modal).modal();

                    $(modal)
                        .find("#addProdutosModalLabel", context)
                        .html("Atualizar produto");

                    $(modal)
                        .find("#produto_oportunidade_id", context)
                        .val(res.id ?? " - ");
                    $(modal)
                        .find("#sku-chat", context)
                        .html(res.produto.sku ?? " - ");
                    $(modal)
                        .find("#produto_id", context)
                        .val(res.produto_id ?? " - ");
                    $(modal)
                        .find("#produto-nome", context)
                        .html(res.produto.nome ?? " - ");
                    let preco = 0;
                    if (res.produto.faixas_precos.length > 0) {
                        preco = parseFloat(res.faixa_preco.preco);
                        if (
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        if (
                            !$(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .addClass("d-none");
                        }

                        $(modal)
                            .find("#faixa-chat", context)
                            .find("option")
                            .remove()
                            .end();
                        $.each(res.produto.faixas_precos, function (i, item) {
                            let precoFaixa = parseFloat(item.preco);
                            $(modal)
                                .find("#faixa-chat", context)
                                .append(
                                    $("<option>", {
                                        value: item.id,
                                        text: `${
                                            item.descricao
                                        } - R$ ${precoFaixa.toLocaleString(
                                            "pt-br",
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}`,
                                    }).attr("data-id", precoFaixa)
                                );
                        });
                    } else {
                        if (
                            !$(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        if (
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        preco = parseFloat(res.produto.preco);
                        $(modal)
                            .find("#bloco-faixa-chat", context)
                            .attr("data-preco", preco);
                        $(modal)
                            .find("#preco-faixa-chat", context)
                            .val(
                                preco.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })
                            );
                    }

                    const precoCobrado = parseFloat(res.preco);
                    let desconto = (precoCobrado * 100) / preco;
                    desconto = 100 - desconto;
                    desconto = desconto < 0 ? 0 : desconto;
                    $("#desconto-chat", context).html(
                       `${desconto.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                        })}%`
                    );
                    $(modal).find("#qdt-chat", context).val(res.quantidade);
                    $(modal)
                        .find("#bloco-faixa-chat", context)
                        .attr("data-preco", precoCobrado);
                    $(modal)
                        .find("#preco-chat", context)
                        .val(
                            precoCobrado.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                            })
                        );
                    const subtotal = precoCobrado * res.quantidade;
                    $(modal)
                        .find("#subtotal-chat", context)
                        .attr("data-id", precoCobrado);
                    $(modal)
                        .find("#subtotal-chat", context)
                        .html(
                            subtotal.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })
                        );
                },
                error: function (err) {
                    $("#overlayer-loading").removeClass("show-overlayer");
                    showToastDanger(context);
                },
            });
        }
    );

    $(".js-chat-adcionar-produto").on("click", function (e) {
        if (window.atualizandoProdutopChat) {
            return false;
        }
        window.atualizandoProdutopChat = true;
        const input = this;
        const form = $(this).closest("form");
        const itemId = $(form).find("#produto_oportunidade_id").val();
        const produtoId = $(form).find("#produto_id").val();
        const oportunidadeId = $(form).find("#oportunidade_id").val();
        const faixaId = $(form).find("#faixa-chat").val();
        const preco = $(form).find("#preco-chat").val();
        const quantidade = $(form).find("#qdt-chat").val();

        if (itemId) {
            atualizarProdutoChat(itemId, faixaId, preco, quantidade, input);
        } else {
            incluirProdutoChat(
                produtoId,
                oportunidadeId,
                faixaId,
                preco,
                quantidade,
                input
            );
        }
        const modal = $(this).closest("#addProdutosModal");
        $(modal).modal("hide");
    });

    function incluirProdutoChat(
        produtoId,
        oportunidadeId,
        faixaId,
        preco,
        quantidade,
        input
    ) {
        $.ajax({
            url: siteUrl("/painel/oportunidades/adicionar-produto"),
            type: "post",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                produto_id: produtoId,
                oportunidade_id: oportunidadeId,
                faixa_id: faixaId,
                preco: preco,
                quantidade: quantidade,
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                context = $(input).closest(".sidebar-chat");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context);
                    const trChat = res.htmlChat;
                    const tr = res.html;
                    const item_id = res.itemId;
                    $(".js-tabela-produtos-chat tbody", context).append(trChat);
                    Eloca.load($(`#tr-item-chat-${item_id}`));
                    autalizarTotalTabela(`#tr-item-chat-${item_id}`);

                    $(".js-tabela-produtos tbody").append(tr);
                    Eloca.load($(`#tr-item-${item_id}`));
                    autalizarTotalTabela(`#tr-item-${item_id}`);

                    if ($(".js-racerregar-modelo").hasClass("d-none")) {
                        $(".js-racerregar-modelo").removeClass("d-none");
                    }
                    fecharModalChat($(input).closest("form"));
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    }

    function atualizarProdutoChat(item_id, faixa_id, preco, quantidade, input) {
        context = $(input).closest(".sidebar-chat");
        $.ajax({
            url: siteUrl("/painel/oportunidades/atualizar-produto"),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: { item_id, faixa_id, preco, quantidade },
            success: function (res) {
                window.atualizandoProdutopChat = false;
                $("#overlayer-loading").removeClass("show-overlayer");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context, res.message);
                    const tr = $(input)
                        .closest(".sidebar-chat")
                        .find(".js-tabela-produtos-chat")
                        .find(`#tr-item-chat-${item_id}`);
                    const trPrincpal = $("#tabela_produtos_principal").find(
                        `#tr-item-${item_id}`
                    );
                    if (quantidade > 0) {
                        const novaTrChat = res.htmlChat;
                        const novaTr = res.html;
                        $(tr, context).replaceWith(novaTrChat);
                        $(trPrincpal).replaceWith(novaTr);
                        Eloca.load($(`#tr-item-chat-${item_id}`));
                        Eloca.load($(`#tr-item-${item_id}`));
                    } else {
                        $(tr).remove();
                        $(trPrincpal).remove();
                    }
                    autalizarTotalTabela(`#tr-item-chat-${item_id}`);
                }
            },
            error: function (err) {
                window.atualizandoProdutopChat = false;
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context, "Falha ao atualizar produto.");
            },
        });
    }

    $(".js-container-chat").on(
        "click",
        ".js-add-produto-mensagem",
        function () {
            const sidebar = $(this)
                .closest(".js-tab-chat")
                .find(".js-bloco-chat");
            if ($(sidebar).hasClass("d-none")) {
                $(this)
                    .closest(".js-tab-chat")
                    .find(".js-sidebar-chat-toggle")
                    .trigger("click");
                $(sidebar).removeClass("d-none");
            }
            const produtoId = $(this).data("id");
            $("#overlayer-loading").addClass("show-overlayer");
            $.ajax({
                url: siteUrl(`/painel/produto-carregar/${produtoId}`),
                type: "get",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                success: function (res) {
                    window.atualizandoProdutopChat = false;
                    $("#overlayer-loading").removeClass("show-overlayer");
                    const modal = $(sidebar)
                        .find(".sidebar-chat")
                        .find("#addProdutosModal");
                    $(modal).modal();

                    $(modal)
                        .find("#addProdutosModalLabel", context)
                        .html("Adicionar produto");
                    $(modal)
                        .find("#produto_id", context)
                        .val(res.produto.id ?? " - ");
                    $(modal)
                        .find("#sku-chat", context)
                        .html(res.produto.sku ?? " - ");
                    $(modal)
                        .find("#produto-nome", context)
                        .html(res.produto.nome ?? " - ");
                    let preco = 0;
                    if (res.produto.faixas_precos.length > 0) {
                        if (
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        if (
                            !$(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        preco = parseFloat(res.produto.faixas_precos[0].preco);
                        $(modal)
                            .find("#faixa-chat", context)
                            .find("option")
                            .remove()
                            .end();
                        $.each(res.produto.faixas_precos, function (i, item) {
                            const precoItem = parseFloat(item.preco);
                            $(modal)
                                .find("#faixa-chat", context)
                                .append(
                                    $("<option>", {
                                        value: item.id,
                                        text: `${
                                            item.descricao
                                        } - R$ ${precoItem.toLocaleString(
                                            "pt-br",
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}`,
                                    }).attr("data-id", precoItem)
                                );
                        });
                    } else {
                        if (
                            !$(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        if (
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        preco = parseFloat(res.produto.preco);
                        $(modal)
                            .find("#bloco-faixa-chat", context)
                            .attr("data-preco", preco);
                        $(modal)
                            .find("#preco-faixa-chat", context)
                            .val(
                                preco.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })
                            );
                    }
                    $(modal)
                        .find("#bloco-faixa-chat", context)
                        .attr("data-preco", preco);
                    $(modal)
                        .find("#preco-chat", context)
                        .val(
                            preco.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                            })
                        );
                    $(modal)
                        .find("#subtotal-chat", context)
                        .attr("data-id", preco);
                    $(modal)
                        .find("#subtotal-chat", context)
                        .html(
                            preco.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })
                        );
                },
                error: function (err) {
                    window.atualizandoProdutopChat = false;
                    $("#overlayer-loading").removeClass("show-overlayer");
                    showToastDanger(context);
                },
            });
        }
    );

    function autalizarTotalTabela(id) {
        let total = 0.0;
        if (id == "tabela_produtos" || id == "tabela_produtos_principal") {
            id = `#${id}`;
            $(id)
                .find(".subtotalSpanProd")
                .each(function () {
                    let val = $(this).data("id");
                    val = limpaMoeda(val);
                    total += val;
                });
        } else {
            $(id)
                .closest("table")
                .find("tbody")
                .find(".subtotalSpanProd")
                .each(function () {
                    let val = $(this).data("id");
                    val = limpaMoeda(val);
                    total += val;
                });
        }
        total += limpaMoeda(
            $(id)
                .closest("table")
                .find("tfoot")
                .find(".js-valor-protecao-ccl")
                .attr("data-valor") ?? 0
        );

        total += limpaMoeda(
            $(id)
                .closest("table")
                .find("tfoot")
                .find("#deslocamento_entrega")
                .val()
        );

        total += limpaMoeda(
            $(id)
                .closest("table")
                .find("tfoot")
                .find("#deslocamento_retirada")
                .val()
        );
        $(id)
            .closest("table")
            .find("#produtoOportunidadeTotal")
            .html(
                total.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })
            );
    }
};
