export function atualizarMascara(tipoPessoa, context) {
    $(".js-cpf-cnpj", context).unmask();
    if (tipoPessoa === "1" || tipoPessoa === "2") {
        $(".js-cpf-cnpj", context).mask("000.000.000-00", {
            reverse: true,
        });
    } else {
        $(".js-cpf-cnpj", context).mask("00.000.000/0000-00", {
            reverse: true,
        });
    }
}

export function controlarVisibilidade(tipoPessoa, context) {
    let label = "Nome/Contato";
    if (tipoPessoa === "1" || tipoPessoa === "2") {
        $(".js-cpf-cnpj", context).mask("000.000.000-00", {
            reverse: true,
        });
        $(".js-dados-pj", context).addClass("d-none");
        $(".js-dados-pf", context).removeClass("d-none");
    } else {
        $(".js-dados-pj", context).removeClass("d-none");
        $(".js-dados-pf", context).addClass("d-none");
        label = "Nome do Contato";
    }
    $(".js-alterar-label-nome label").text(label);
    $(".js-alterar-label-nome input").attr("placeholder", label);
}

export function verificarTipoDeArquivo(tipo, tipoMIME) {
    if (tipo != "image" && tipo != "video") {
        return true;
    }

    if (tipo == "image" && tipoMIME.startsWith("image/")) {
        return true;
    } else if (tipo == "video" && tipoMIME.startsWith("video/")) {
        return true;
    }
    return false;
}

export var tempKeyPressed = "";

export function validarTelefone(e, context, ctrlVaction = false) {
    let input = $(".js-telefone-validar-whatsapp", context);
    $(".js-resultado-consulta-whatsapp").html("");
    $(".js-icon-resultado-consulta-whatsapp").css("color", "black");
    $(".js-icon-resultado-consulta-whatsapp").html(
        "<i class='fas fa-phone'></i>"
    );

    let ddi = $("input[name='telefone1_ddi']").val();
    let telefoneInput = input.val().replace(/\D/g, "");
    if (/^[0-9]$/.test(e.key) || ctrlVaction) {
        if (telefoneInput.length >= 10 && tempKeyPressed.length <= 11) {
            $.ajax({
                url: siteUrl(
                    `/painel/clientes/validar-whatsapp/${ddi}/${telefoneInput}`
                ),
                type: "get",
                success: function (res) {
                    if (res.sucesso == true) {
                        $(".js-resultado-consulta-whatsapp").html(
                            "Esse número possui uma conta de whatsapp"
                        );
                        $(".js-resultado-consulta-whatsapp").css(
                            "color",
                            "green"
                        );
                        $(".js-icon-resultado-consulta-whatsapp").html(
                            "<i class='fas fa-check-square'></i>"
                        );
                        $(".js-icon-resultado-consulta-whatsapp").css(
                            "color",
                            "green"
                        );
                    } else {
                        $(".js-resultado-consulta-whatsapp").html(
                            "Esse número não possui uma conta de whatsapp"
                        );
                        $(".js-resultado-consulta-whatsapp").css(
                            "color",
                            "red"
                        );
                        $(".js-icon-resultado-consulta-whatsapp").html(
                            "<i class='fas fa-window-close'></i>"
                        );
                        $(".js-icon-resultado-consulta-whatsapp").css(
                            "color",
                            "red"
                        );
                    }
                },
                error: function (err) {
                    console.log(err);
                },
            });
        }
    }
}
