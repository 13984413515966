const functions = require("../functions").default;

export function modalEvents(context, isEmpty) {
    if ($(".js-show-modal", context).length) {
        $(".js-show-modal", context).modal({
            show: true,
        });
    }

    $(".modal-ajax").on("show.bs.modal", function (event) {
        let modal = this;
        const url = $(this).data("url");
        $.ajax({
            url: url,
            type: "get",
            success: function (res) {
                $(".js-body-modal-ajax", modal).html(res);
            },
        });
    });

    $(".js-modal-video", context).on("hidden.bs.modal", function () {
        $("iframe", this).attr("src", $("iframe", this).attr("src"));
    });

    $(document).on(
        "click",
        'button[data-target="#modalDetalhesEmail"]',
        function (e) {
            e.preventDefault();

            var modalID = $(this).attr("data-id");
            //var corpo_email_url = $(this).attr("data-corpo_email_url");
            $.ajax({
                url: siteUrl(`/painel/log-emails/${modalID}`),
                data: {},
                type: "GET",
                dataType: "json",
                success: function (response) {
                    var url_corpo_email = response.url_corpo_email;
                    $(".item_assunto").html(response.assunto);
                    $(".item_destinatarios").html(response.destinatarios);
                    $(".item_destonatarios_cc").html(response.destonatarios_cc);
                    $(".item_tipo_destinatario").html(
                        response.tipo_destinatario
                    );
                    $(".item-status-email").html(response.status);
                    $(".item-status-email")
                        .removeClass()
                        .addClass("item-status-email " + response.status);

                    $(".item_smtp").html(response.smtp);

                    $(".item_data_envio").html(
                        functions.dateToBrDate(response.created_at)
                    );
                    $(".item_data_reenvio").html(
                        functions.dateToBrDate(response.reenvio)
                    );
                    if (isEmpty(url_corpo_email) == false) {
                        $(".divTargetHTMLmail").attr("srcdoc", url_corpo_email);
                        functions.resizeIframe("divTargetHTMLmail");
                    }

                    $(".url_reenviar_email").attr(
                        "href",
                        "log-emails/reenviar/" + response.id
                    );
                },
                error: function (erro) {
                    alert("ERRO");
                },
            });
        }
    ); // end click modalDetalhesEmail

    $(".js-check-pipeline-user", context).on("change", function () {
        const modal = "#modalConfirmDelete-" + $(this).attr("id");
        const bloco = $(this).closest(".js-pipeline-bloco");

        if (!$(this).is(":checked") && $(bloco).attr("data-id") == 1) {
            $(modal).modal("toggle");
        }
    });
}
