const Cropper = require("cropperjs");

Eloca.behaviors.imagem = function (context) {

    window.timeout = null;
    window.video = null;
    window.cropper = null;

    $(".js-modal-avatar-cliente").on("click", function() {
        $(".js-foto-cliente-input").trigger("click");
    });

    $(".js-foto-cliente").on("click", function() {
        $(".js-crop-container").toggleClass("d-none");
        $(".js-controle-crop").toggleClass("d-none");

        $(".video-avatar").toggleClass("d-none");
        $(".js-controle-video").toggleClass("d-none");

        navigator.mediaDevices.getUserMedia({video: true})
            .then(function (mediaStream) {
                window.video = document.querySelector('#video');
                window.video.srcObject = mediaStream;
                window.video.play();
            })
            .catch(function (err) {
                console.log('Não há permissões para acessar a webcam')
            })
    });

    $(".js-cancelar-captura").on("click", function() {
        $(".js-crop-container").toggleClass("d-none");
        $(".js-controle-crop").toggleClass("d-none");

        $(".video-avatar").toggleClass("d-none");
        $(".js-controle-video").toggleClass("d-none");

        if (window.video != null) {
            window.video.srcObject.getTracks()[0].stop();
            window.video = null;
        }
    });

    $(".js-capturar-imagem").on("click", function() {
        if (window.cropper != null) {
            window.cropper.destroy();
        }
        const canvasAvatar = $(".canvas-avatar").get(0);
        canvasAvatar.height = window.video.videoHeight;
        canvasAvatar.width = window.video.videoWidth;
        const context = canvasAvatar.getContext('2d');
        context.drawImage(video, 0, 0);
        window.cropper = new Cropper(
            canvasAvatar,
            {
                aspectRatio: 8 / 8,
                crop(event) {},
            }
        );

        $(".js-crop-container").toggleClass("d-none");
        $(".js-controle-crop").toggleClass("d-none");

        $(".video-avatar").toggleClass("d-none");
        $(".js-controle-video").toggleClass("d-none");

        window.video.srcObject.getTracks()[0].stop();
        window.video = null;
    });


    $(".js-foto-cliente-input").on("change", function () {
        if (this.files && this.files[0]) {
            const canvasAvatar = $(".canvas-avatar");
            context = canvasAvatar.get(0).getContext("2d");
            if (this.files[0].type.match(/^image\//)) {
                if (window.cropper != null) {
                    window.cropper.destroy();
                }
                const reader = new FileReader();
                reader.onload = function (evt) {
                    const img = new Image();
                    img.onload = function () {
                        context.canvas.height = img.height;
                        context.canvas.width = img.width;
                        context.drawImage(img, 0, 0);
                        window.cropper = new Cropper(
                            canvasAvatar.get(0),
                            {
                                aspectRatio: 8 / 8,
                                crop(event) {},
                            }
                        );
                    };
                    img.src = evt.target.result;
                };
                reader.readAsDataURL(this.files[0]);
            } else {
                showToastWarning(
                    context,
                    "Tipo de aquivo invalido! Por favor selecione uma imagem."
                );
            }
        } else {
            showToastWarning(
                context,
                "Nenhum arquivo escolhido."
            );
        }
    });

    $(".js-btn-restore").on("click", function () {
        window.cropper.reset();
    });


    $(".js-zoom-in").on("mousedown", function () {
        if (window.timeout == null) {
            window.timeout = setInterval(function () {
                window.cropper.zoom(0.1);
            }, 25);
        }

        return false;
    });

    $(".js-zoom-in").on("mouseup", function () {
        return limparRepeticao();
    });

    $(".js-zoom-out").on("mousedown", function () {
        if (window.timeout == null) {
            window.timeout = setInterval(function () {
                window.cropper.zoom(-0.1);
            }, 25);
        }
        return false;
    });

    $(".js-zoom-out").on("mouseup", function () {
        return limparRepeticao();
    });

    $(".js-btn-girar-dir").on("mousedown", function () {
        if (window.timeout == null) {
            window.timeout = setInterval(function () {
                window.cropper.rotate(5);
            }, 25);
        }

        return false;
    });

    $(".js-btn-girar-dir").on("mouseup", function () {
       return limparRepeticao();
    });

    $(".js-btn-girar-esq").on("mousedown", function () {
        if (window.timeout == null) {
            window.timeout = setInterval(function () {
                window.cropper.rotate(-5);
            }, 25);
        }

       return false;
    });

    $(".js-btn-girar-esq").on("mouseup", function () {
        return limparRepeticao();
    });

    $(".js-btn-mover-cima").on("mousedown", function () {
        if (window.timeout == null) {
            window.timeout = setInterval(function () {
                window.cropper.move(0, -2);
            }, 25);
        }
       return false;
    });

    $(".js-btn-mover-cima").on("mouseup", function () {
        return limparRepeticao();
    });


    $(".js-btn-mover-baixo").on("mousedown", function () {
        if (window.timeout == null) {
            window.timeout = setInterval(function () {
                window.cropper.move(0, 2);
            }, 25);
        }

       return false;
    });

    $(".js-btn-mover-baixo").on("mouseup", function () {
        return limparRepeticao();
    });

    $(".js-btn-mover-esq").on("mousedown", function () {
        if (window.timeout == null) {
            window.timeout = setInterval(function () {
                window.cropper.move(-2, 0);
            }, 25);
        }

        return false;
    });

    $(".js-btn-mover-esq").on("mouseup", function () {
        return limparRepeticao();
    });

    $(".js-btn-mover-dir").on("mousedown", function () {
        if (window.timeout == null) {
            window.timeout = setInterval(function () {
                window.cropper.move(2, 0);
            }, 25);
        }

        return false;
    });

    $(".js-btn-mover-dir").on("mouseup", function () {
        return limparRepeticao();
    });

    $(".js-add-imagem-cliente").on("click", function () {
        $("#overlayer-loading").addClass("show-overlayer");

        window.cropper
            .getCroppedCanvas({
                fillColor: '#fff',
                imageSmoothingEnabled: false,
                imageSmoothingQuality: 'high',
            })
            .toBlob(
                function (blob) {
                    const formData = new FormData();
                    const id = $("#id-cliente").val();
                    formData.append("clienteId", id);
                    formData.append("foto", blob, "imagetest.jpeg");

                    $.ajax({
                        url: siteUrl(`/painel/clientes/add-foto`),
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            ),
                        },
                        type: "post",
                        data: formData,
                        contentType: false,
                        processData: false,
                        success: function (res) {
                            if (!res.error) {
                                window.location.reload(true);
                            } else {
                                showToastDanger(context, res.message);
                            }
                        },
                        error: function (err) {
                            console.log(err);
                            showToastDanger(
                                context,
                                "Se persistir entre em contato com o suporte."
                            );
                            $("#overlayer-loading").removeClass(
                                "show-overlayer"
                            );
                        },
                    });
                },
                "image/jpeg",
                1
            );

    });

    function limparRepeticao() {
        clearInterval(window.timeout);
        window.timeout = null;
        return false;
    }

}
