export function recalcularPrecoProdutos(context) {
    let valorTotal = 0;
    let valorTotalInstalacao = 0.0;

    if ($(".js-switch-custo-instalacao", context).is(":checked")) {
        valorTotalInstalacao = parseFloat(
            $(".js-custo-instalacao", context).val()
        );
    }

    $(".js-tr-produtos").each(function () {
        let preco = 0.0;
        if ($(".js-att-preco", this).val() != undefined) {
            preco = parseFloat(
                $(".js-att-preco", this)
                    .val()
                    .replaceAll(".", "")
                    .replace(",", ".")
            );
        }

        const idProduto = $(".js-id-produto", this).val();

        const quantProduto = $(".js-id-produto-quantidade", this).val();

        const precoFinalProduto = parseInt(quantProduto) * preco;

        valorTotal += precoFinalProduto;

        $(".subtotalSpan-" + idProduto).html(
            "R$ " + parseFloat(precoFinalProduto).toFixed(2).replace(".", ",")
        );
    });

    let adicional = 0.0;
    let desconto = 0.0;
    $("#js-adicional-descricao").prop("required", false);
    if ($("#js-valor-adicional", context).val()) {
        adicional = parseFloat(
            $("#js-valor-adicional", context)
                .val()
                .replaceAll(".", "")
                .replace(",", ".")
        );

        if (adicional > 0) {
            $("#js-adicional-descricao").prop("required", true);
        }
    }
    $(".js-show-valor-adicional", context).html(
        adicional.toFixed(2).replace(".", ",")
    );
    if ($("#js-valor-desconto", context).val()) {
        desconto = parseFloat(
            $("#js-valor-desconto", context)
                .val()
                .replaceAll(".", "")
                .replace(",", ".")
        );
    }
    $(".js-show-valor-desconto", context).html(
        desconto.toFixed(2).replace(".", ",")
    );

    $(".js-show-subtotal", context).html(
        `R$ ${valorTotal.toFixed(2).replace(".", ",")}`
    );

    $(".totalSpan").html(
        "R$ " +
            parseFloat(valorTotal + valorTotalInstalacao + adicional - desconto)
                .toFixed(2)
                .replace(".", ",")
    );
}

export function recalcularPrecosEdicaoAssinatura(context) {
    let total = 0;
    $(".js-tr-produtos", context).each(function (i, tr) {
        let preco = 0;
        if ($(".js-input-preco-item-assinatura", tr).length > 0) {
            preco = parseFloat(
                $(".js-input-preco-item-assinatura", tr)
                    .val()
                    .replaceAll(".", "")
                    .replace(",", ".")
            );
        }
        total += preco;
        $(".subtotal-tr", tr).html(numeral(preco).format("$ 0,0.00"));
    });
    $(".totalSpan", context).html(numeral(total).format("$ 0,0.00"));
}
