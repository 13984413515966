const { desenhaQrCode } = require('./miscellaneous');

export function buscaCEP(cep, context) {
    if (cep.length < 9) {
        return;
    }

    $("#overlayer-loading").addClass("show-overlayer");
    $.ajax({
        url: siteUrl(`/cidades/cep/${cep}`),
        type: "get",
        success: function (res) {
            if (!res.error && res.dados) {
                const { busca, cidades, estado_id, cidade_id } = res.dados;
                $("#rua", context).val(busca.logradouro);
                $("#bairro", context).val(busca.bairro);

                if (estado_id) {
                    $(".js-select-estados", context).val(estado_id);
                    $(".js-select-cidades option", context).remove();
                    cidades.forEach(function (item) {
                        $(".js-select-cidades", context).append(
                            `<option value="${item.id}">${item.value}</option>`
                        );
                    });
                    if (cidade_id) {
                        $(".js-select-cidades", context).val(cidade_id);
                    }
                }
                $("#numero", context).trigger("focus");
            }
            $("#overlayer-loading").removeClass("show-overlayer");
        },
    });
}

export function qrCodeAjax(context, sessaoId) {
    console.log("qrCodeAjax");
    if ($(`.js-row-whatsapp-web-qrcode`, context).length) {
        $.ajax({
            url: siteUrl("/painel/whatsapp-web/qrcode-ajax/" + sessaoId),
            type: "get",
            success: function (res) {
                if (res.live) {
                    window.location.href = siteUrl(
                        `/painel/fluxo-oportunidades`
                    );
                    return;
                }

                if (res.sucesso) {
                    desenhaQrCode(
                        document.getElementById("js-saida-qrcode"),
                        res.qrCode
                    );
                }
            },
            error: function (error) {
                console.log(error);
            },
        });
    }
}

export function calcChargeValue() {
    let novoValorTotalCobranca = 0.0;

    $(".js-modal-cobranca .js-row-cobranca").each(function () {
        if ($(".js-checkbox-cobranca", this).is(":checked")) {
            novoValorTotalCobranca += parseFloat(
                $(".js-input-value-cobranca", this)
                    .val()
                    .replace(".", "")
                    .replace(",", ".")
            );
        }
    });

    $(".valorTotalCobranca").val(
        novoValorTotalCobranca.toFixed(2).replace(".", ",")
    );
}

export function insereOportunidadeKanban(oportunidadeId, div) {
    $.ajax({
        url: siteUrl("/painel/fluxo-oportunidades/kanban/" + oportunidadeId),
        type: "GET",
        success: function (res) {
            if (!res.error) {
                const primeiraFase = $(`#${div} .js-fase:first`);
                $("ul", primeiraFase).prepend(res.li);
            }
        },
    });
}

export function enviaOportunidadeParaOReact(oportunidadeId) {
    $.ajax({
        url: siteUrl(
            "/painel/fluxo-oportunidades/oportunidade/" + oportunidadeId
        ),
        type: "GET",
        success: function (res) {
            if (!res.error) {
                const iframe =
                    document.getElementById("react-app").contentWindow;
                const message = {
                    oportunidade: res.oportunidade,
                };

                iframe.postMessage(message, "*");
            }
        },
    });
}
