const { verificarTipoDeArquivo } = require("./validation");
const { enviaMensagemChat } = require("./chat_features");

export function filesToArray(arquivos) {
    let files = [];
    for (let i = 0; i < arquivos.length; i++) {
        files.push(arquivos[i]);
    }
    return files;
}

export function uploadOtherFile(file, tipo, input) {
    const fileName = file.name;
    const contextChat = $(input).closest(".oportunidade-chat");

    if (!verificarTipoDeArquivo(tipo, file.type)) {
        showToastDanger($("body"), "Arquivo inválido, tente novamente.");
        return false;
    }

    $(".js-sending-chat", contextChat).removeClass("d-none");
    Vapor.store(file, {
        baseURL: siteUrl("/painel"),
        progress: (progress) => {
            //this.uploadProgress = Math.round(progress * 100);
        },
    })
        .then((response) => {
            enviaMensagemChat(
                contextChat,
                response.uuid,
                file,
                tipo,
                file.type,
                fileName
            );
        })
        .catch((error) => {
            console.log(error);
            alert("Não foi possível enviar o arquivo");
            $(".js-sending-chat", contextChat).addClass("d-none");
        });
}

export function uploadImg(file, tipo, input) {
    const contextChat = $(input).closest(".oportunidade-chat");

    if (!verificarTipoDeArquivo(tipo, file.type)) {
        showToastDanger($("body"), "Arquivo inválido, tente novamente.");
        return false;
    }

    $(".js-sending-chat", contextChat).removeClass("d-none");

    const chatContext = input.closest(".oportunidade-chat");
    const canvas = chatContext.getElementsByClassName("js-canvas-imagem")[0];
    const canvasImg = canvas.getContext("2d");

    const reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(file);
    reader.onload = function (e) {
        const tmpImage = new Image();
        tmpImage.src = e.target.result;
        tmpImage.crossOrigin = "anonymous";
        tmpImage.onload = function () {
            canvas.width = this.width;
            canvas.height = this.height;

            canvasImg.drawImage(tmpImage, 0, 0);
            const imgData = canvasImg.getImageData(
                0,
                0,
                canvas.width,
                canvas.height
            );
            const data = imgData.data;
            let transparent = false;
            for (let i = 0; i < data.length; i += 4) {
                const alfa = data[i + 3];
                if (alfa < 255) {
                    transparent = true;
                    break;
                }
            }
            if (transparent || file.type == "image/webp") {
                canvasImg.fillStyle = "#FFFFFF";
                canvasImg.fillRect(0, 0, this.width, this.height);
                canvasImg.drawImage(tmpImage, 0, 0);
                canvas.toBlob((blob) => {
                    let file = new File([blob], "fileName.jpg", {
                        type: "image/jpeg",
                    });
                    Vapor.store(file, {
                        baseURL: siteUrl("/painel"),
                        progress: (progress) => {
                            this.uploadProgress = Math.round(progress * 100);
                        },
                    })
                        .then((response) => {
                            enviaMensagemChat(
                                contextChat,
                                response.uuid,
                                file,
                                tipo
                            );
                        })
                        .catch((error) => {
                            alert("Não foi possível enviar a imagem");
                            $(".js-sending-chat", contextChat).addClass(
                                "d-none"
                            );
                        });
                }, "image/jpeg");
            } else {
                Vapor.store(file, {
                    baseURL: siteUrl("/painel"),
                    progress: (progress) => {
                        this.uploadProgress = Math.round(progress * 100);
                    },
                })
                    .then((response) => {
                        enviaMensagemChat(
                            contextChat,
                            response.uuid,
                            file,
                            tipo
                        );
                    })
                    .catch((error) => {
                        alert("Não foi possível enviar a imagem");
                        $(".js-sending-chat", contextChat).addClass("d-none");
                    });
            }
        };
    };
}
