const functions = require("../functions").default;

export function miscellaneousEvents(context) {
    $("#js-valor-desconto, #js-valor-adicional", context).on(
        "change",
        function () {
            functions.recalcularPrecoProdutos(context);
        }
    );

    if ($(".js-select-tabs", context).length) {
        const tabs = $(".js-select-tabs", context);

        $(".js-select-tabs a", context).on("click", function () {
            const href = this.href.split("#");
            if (href.length == 2) {
                $(".js-active-tab").val(`#${href[1]}`);
                window.location.hash = href[1];
            }
        });

        const hash = window.location.hash;
        const ativa = $(".js-active-tab").val();
        if (hash) {
            $("a", tabs).each(function (index, tab) {
                const href = tab.href.split("#");
                if (href.length == 2 && `#${href[1]}` == hash) {
                    $(tab).trigger("click");
                }
            });
        }
    }

    $(".text-editor", context).each(function () {
        let editorInstance;

        console.log("editorInstance", editorInstance);

        ClassicEditor.create(this, {
            alignment: {
                options: ["left", "right", "justify"],
            },
            language: "pt-br",
            image: {
                toolbar: [
                    "imageTextAlternative",
                    "imageStyle:inline",
                    "imageStyle:block",
                    "imageStyle:side",
                ],
            },
            mediaEmbed: {
                toolbar: ["HtmlEmbed"],
            },
            removePlugins: ["Title"],
            toolbar: [
                "heading",
                "alignment",
                "fontsize",
                "|",
                "fontColor",
                "fontBackgroundColor",
                "|",
                "bold",
                "italic",
                "|",
                "link",
                "|",
                "outdent",
                "indent",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "blockQuote",
                "imageUpload",
                "|",
                "undo",
                "redo",
                "|",
                "HtmlEmbed",
                "sourceEditing",
            ],
            simpleUpload: {
                uploadUrl: siteUrl("/painel/imagem/upload-editor"),
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
            },
        })
            .then((editor) => {
                editorInstance = editor;

                document
                    .getElementById("insert-video-button")
                    .addEventListener("click", () => {
                        console.log("cliquei");

                        const videoHtml = `<div class="raw-html-embed">
            <figure class="video">
                <video controls width="100%" controls="" playsinline="">
                    <source src="COLOQUE_A_URL_DO_VIDEO_AQUI" type="video/mp4">
                    Seu navegador não suporta o elemento <code>video</code>.
                </video>
            </figure>
            </div>`;

                        if (editorInstance) {
                            // Insere o conteúdo HTML de vídeo no editor
                            editorInstance.model.change((writer) => {
                                // Insere o HTML como um fragmento de dados no modelo do editor
                                const videoFragment =
                                    editorInstance.data.processor.toView(
                                        videoHtml
                                    );
                                const modelFragment =
                                    editorInstance.data.toModel(videoFragment);

                                // Obtém a posição atual do cursor e insere o conteúdo
                                editorInstance.model.insertContent(
                                    modelFragment,
                                    editorInstance.model.document.selection
                                );
                            });
                        } else {
                            console.error("Editor ainda não está pronto.");
                        }
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    });

    $(".js-btn-qtd-plus", context).on("click", function () {
        const box = $(this).closest(".js-box-quantidade-produto");
        const quant = $(".js-input-quantidade", box).val();
        $(".js-input-quantidade", box)
            .val(parseInt(quant) + 1)
            .trigger("change");
    });

    $(".js-btn-qtd-minus", context).on("click", function () {
        const box = $(this).closest(".js-box-quantidade-produto");
        const quant = $(".js-input-quantidade", box).val();
        if (quant >= 0) {
            $(".js-input-quantidade", box)
                .val(parseInt(quant) - 1)
                .trigger("change");
        }
    });

    $(".js-select-pais", context).on("click", function () {
        const box = $(this).closest(".input-group");
        $(".js-dropdown-telefone", box).html($(this).html());
        $(".js-ddi-telefone", box).val($(this).data("pais-ddi"));
        $(".js-pais-telefone", box).val($(this).data("pais-id"));
        $(".js-dropdown-telefone", box).data(
            "mascara",
            $(this).data("mascara")
        );
        $(".js-dropdown-telefone", box).trigger("change");
    });

    //recalcula valores na edição de preço da assinatura
    $(
        "#js-desconto, .js-input-preco,  .js-input-preco-item-assinatura",
        context
    ).on("change", function () {
        functions.recalcularPrecosEdicaoAssinatura(context);
    });

    $(".js-input-color", context).on("change", function () {
        let tipo = $(this).closest(".form-group").data("tipo");
        let posicao = $(this).closest(".form-group").data("posicao");

        if (tipo == "text") {
            $(`.layout .${posicao}`, context).css("color", this.value);
        } else if (tipo == "border") {
            $(`.layout .${posicao}`, context).css("border-color", this.value);
        } else {
            $(`.layout .${posicao}`, context).css(
                "background-color",
                this.value
            );
        }
    });

    $(".js-show-loading", context).on("click", function () {
        $(this).attr("disabled", "disabled");
        $("#overlayer-loading").addClass("show-overlayer");
    });

    $(".js-sidenav a:not(.collapsed, .loja-nome)", context).on(
        "click",
        function () {
            $("#overlayer-loading").addClass("show-overlayer");
        }
    );

    if ($("#minhas-oportunidades").length) {
        $(
            `#minhas-oportunidades .fase .conteudo li.oportunidade a[href='${window.location.href}']`
        )
            .parent()
            .addClass("oportunidade-aberta");
    }

    $(".js-copy-link-pagamento", context).on("click", function (e) {
        const contextChat = $(this).closest(".oportunidade-chat");
        if ($(".js-input-chat", contextChat).length > 0) {
            $(".js-input-chat", contextChat).val(
                $(".js-input-chat", contextChat).val() +
                    "" +
                    $(this).attr("data-link")
            );
        }
    });

    $(".elemento-copy-link", context).on("click", function (event) {
        event.preventDefault();

        const contextChat = $(this).closest(".oportunidade-chat");

        const inputTemporario = document.createElement("input");
        inputTemporario.setAttribute("type", "text");
        inputTemporario.setAttribute("value", $(this).attr("data-link"));

        document.body.appendChild(inputTemporario);
        inputTemporario.select();
        if (document.execCommand("copy")) {
            if ($(".js-link-copiado").length > 0) {
                $(".js-link-copiado").css("display", "inline");
            }
            if ($("textarea.js-input-chat", contextChat).length > 0) {
                $("textarea.js-input-chat", contextChat).val(
                    $("textarea.js-input-chat", contextChat).val() +
                        "" +
                        $(this).attr("data-link")
                );
            }

            showToastSuccess(context, "Link copiado");
        }
        document.body.removeChild(inputTemporario);
    });

    $(".js-voip-type", context).on("change", function () {
        window.location.href = siteUrl(
            `/painel/voip?voip_type=${$(this).val()}`
        );
    });

    $(".js-voip-type-evento", context).on("change", function () {
        window.location.href = siteUrl(
            `/painel/voip/eventos?telefonia=${$(this).val()}`
        );
    });

    $(".js-select-telefonias-ramais", context).on("change", function () {
        $(".js-form-prevent-resend").submit();
    });

    $(".js-dados-filial", context).on("change", function () {
        if ($(this).is(":checked")) {
            $(".js-bloco-filial", context).removeClass("d-none");
        } else {
            $(".js-bloco-filial", context).addClass("d-none");
        }
    });

    $(".js-colapase-lista-produtos", context).on("click", function (e) {
        const id = $(this).data("id");
        const btnAbre = `#abre-collapse-${id}`;
        const btnFecha = `#fecha-collapse-${id}`;
        $(btnAbre).toggleClass("d-none");
        $(btnFecha).toggleClass("d-none");
    });

    $(".js-select-tipo-atendimento", context).on("click", function (e) {
        const id = `#select-${$(this).val()}`;
        if ($(this).is(":checked")) {
            $(id).attr("disabled", false);
        } else {
            $(id).attr("disabled", true);
        }
    });

    $(".js-mostrar-lista-usuarios", context).on("change", function (e) {
        if (! $(this).is(":checked")) {
            const modal = `#${$(".js-move-inativar").attr("data-target")}`;
            $(modal).modal("toggle");
        }
    });

    $(".js-aviso-change", context).on("change", function (e) {
        $(".js-aviso-texto").removeClass("d-none");
    });

    $(context).on("click", ".js-gerar-novo-ia-token", function () {
        $("input[name='integracao_mensagens_token']").val(
            functions.generateRandomToken(32)
        );
        $("#modalIaToken").modal("toggle");
    });

    $(".js-cancelar-mover-oportunidades", context).on("click", function () {
        const pipeline = $(this).attr("data-pipeline");
        $(`input[name='pipelines[${pipeline}]']`).trigger("click");
    });

    $(".js-periodo-filtro-dash-loja").on("change", function (e) {
        if ($(this).val() == "custom") {
            if ($(".js-periodo-customizado-loja").hasClass("d-none")) {
                $(".js-periodo-customizado-loja").removeClass("d-none");
            }
        } else if ($(this).val() == "s") {
            if (!$(".js-periodo-customizado-loja").hasClass("d-none")) {
                $(".js-periodo-customizado-loja").addClass("d-none");
            }
        } else {
            $("#data_inicio").val("");
            $("#data_fim").val("");
            $(this).closest("form").submit();
        }
    });
}
