window.siteUrl = function (params) {
    return window.baseUrl + params;
};

window.resizeIFrame = function (id) {
    var height = $(document).height();

    // Backwards – send message to parent
    window.parent.postMessage(
        [
            "setHeightIFrame",
            {
                height: height,
                id: id,
            },
        ],
        "*"
    );
};

window.resizeIframe2 = function (iframe) {
    iframe.style.height =
        iframe.contentWindow.document.body.scrollHeight + "px";
};

window.showToastSuccess = function (context, msg) {
    if (msg == '' || msg == undefined) {
        msg = 'As alterações foram salvas com sucesso.';
    }
    $(".js-show-toast.toast__success .js-toast-message", context).html(msg);
    $(".js-show-toast.toast__success", context).toast("show");
}

window.showToastDanger = function (context, msg) {
    if (msg == '' || msg == undefined) {
        msg = 'Não foi possível salvar as alterações.';
    }
    $(".js-show-toast.toast__danger .js-toast-message", context).html(msg);
    $(".js-show-toast.toast__danger", context).toast("show");
}

window.showToastWarning = function (context, msg) {
    if (msg == "" || msg == undefined) {
        msg = "Tente novamente.";
    }
    $(".js-show-toast.toast__warning .js-toast-message", context).html(msg);
    $(".js-show-toast.toast__warning", context).toast("show");
}
